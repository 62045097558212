import { ref } from "vue";
import "cordova-plugin-purchase/www/store";
import { isPlatform } from "@ionic/core";
import { initializeApollo } from "@/apollo";
import { MobilePlans } from "@/graphql/queries/mobilePlans";
import { PaidSubscriptionStatus, User } from "@/gql/graphql";
import StorageService from "@/helpers/storageService";
import { isMobile } from "./isMobile";
import { setUnlockedButton } from "./setUnlockSubscribeButtonOnInAppError";
import { setPaymentLoader } from "./setPaymentLoader";
import { isEmpty } from "lodash";
import dayjs from 'dayjs';
import { useToast } from "vue-toastification";
import { Team } from "@/graphql/queries/team";
import { Device } from '@capacitor/device';
import { toggleBlock } from "./setBlockUpdateUserOnSwapSubscription";
import { encodeIdToUuid } from "./encodeIdToUuid";

const identifier = ref();
const iapProduct = ref();
const products = ref();
const backPlans: any = ref([]);
const lastProduct = ref();
const { store, ProductType, Platform, LogLevel, TransactionState, GooglePlay } = CdvPurchase;
const validationUrl = ref();
const offerId = ref();
const priceString = ref();
const futurePlanTransactionId = ref();

const getPlatform = (Platform: any) => {
  return isPlatform('android') ? Platform.GOOGLE_PLAY : Platform.APPLE_APPSTORE
}

const productApproved = (transaction: any) => {
  const user: User = StorageService.getUser();
  const isCurrentAppleSubscription = transaction?.transactionId === StorageService.getItem('currentPlan')?.transaction_id;
  const isCurrentGoogleSubscription = true;

  if((isCurrentAppleSubscription && transaction?.state === TransactionState.FINISHED || isCurrentAppleSubscription && transaction?.state === TransactionState.APPROVED || StorageService.getItem('currentPlan')?.id !== transaction?.products?.[0]?.id && transaction?.state !== TransactionState.FINISHED || !StorageService.getItem('currentPlan') || StorageService.getItem('currentPlan')?.expiryDate < new Date().valueOf() && transaction?.state === TransactionState.APPROVED) && isPlatform('ios')) {
    const currentPlan =  StorageService.getItem('mobilePlans')?.find((p: any) => p?.id === transaction?.products?.[0]?.id);
    priceString.value = `${currentPlan?.offers?.[0]?.pricingPhases?.[0]?.price}|${currentPlan?.offers?.[0]?.pricingPhases?.[0]?.currency}`

    validationUrl.value = `${process.env.VUE_APP_IAP_RECEIPT_VALIDATION_URL}${user.id}/${transaction?.products?.[0]?.id}/${priceString.value}/${identifier.value}`;
    store.validator = validationUrl.value;

    transaction.verify();
  } else if((isCurrentGoogleSubscription && transaction?.state === TransactionState.FINISHED || isCurrentGoogleSubscription && transaction?.state === TransactionState.APPROVED || StorageService.getItem('currentPlan')?.id !== transaction?.products?.[0]?.id && transaction?.state !== TransactionState.FINISHED || !StorageService.getItem('currentPlan')) && isPlatform('android')) {
    if(offerId.value) {
      validationUrl.value = `${process.env.VUE_APP_IAP_RECEIPT_VALIDATION_URL}${user.id}/${offerId.value}/${priceString.value}/${identifier.value}`;
    } else {
      validationUrl.value = `${process.env.VUE_APP_IAP_RECEIPT_VALIDATION_URL}${user.id}/${transaction?.nativePurchase?.profileId.split('|')[1]}/${priceString.value}/${identifier.value}`;
    }
    store.validator = validationUrl.value;
    transaction.verify();
  } else {
    transaction.finish();
  }
}

const productUnverified = (p: any) => {
  if(p?.payload?.app_code === '1000') {
    StorageService.setItem('subscriptionOfAnotherUser', true)

    setPaymentLoader(false);
    setUnlockedButton(true);
    toggleBlock(false);
    
    const toast = useToast();
    toast.error('Your device is already associated with a subscription for a different team. Please cancel your current subscription to continue.')
  }
  p.receipt.finish();
}

const productVerified = async (p: any) => {
  const user: User = StorageService.getUser();
  if(user) {
    await initializeApollo().query({
      query: Team,
      variables: {id: user?.team?.id}
    }).then((response) => {
      const futurePlan = response.data.team?.allPlans?.find((p: any) => p?.status === PaidSubscriptionStatus.Future);
      const currentPlanTransactionId = response.data.team?.allPlans?.find((p: any) => p?.status === PaidSubscriptionStatus.Current)?.transaction_id;
      p.finish();
  
      const lastProductTimestamp = dayjs(lastProduct.value?.collection?.[0]?.purchaseDate).valueOf();
      const newProductTimestamp = dayjs(p?.collection?.[0]?.purchaseDate).valueOf();
      const lastProductExpiryDateTimestamp = lastProduct.value?.collection?.[0]?.expiryDate;
      // const newProduct = lastProduct.value?.collection?.[0];
      const dateNowTimestamp = dayjs().valueOf();
  
      const isCurrentAndFutureTransactionsEqual = !isEmpty(currentPlanTransactionId.value) && !isEmpty(futurePlanTransactionId.value) && currentPlanTransactionId.value === futurePlanTransactionId.value;
  
      if(isPlatform('ios') && (!lastProduct.value || ((lastProductTimestamp < newProductTimestamp || lastProductExpiryDateTimestamp < dateNowTimestamp) && !(futurePlan && p?.collection?.[0]?.transaction_id === futurePlan?.transaction_id)) || (lastProductTimestamp < newProductTimestamp && !futurePlan))) { // last changes
        lastProduct.value = p;
  
        StorageService.setItem('currentPlan', lastProduct.value?.collection[0])
        } else if(isPlatform('android') && (!lastProduct.value || (lastProductExpiryDateTimestamp < dateNowTimestamp && !(futurePlan && p?.collection?.[0]?.transaction_id === futurePlan?.transaction_id)) || !futurePlan || isCurrentAndFutureTransactionsEqual)) { // last changes
        
        lastProduct.value = p;
  
        StorageService.setItem('currentPlan', {...lastProduct.value?.collection[0], id: lastProduct.value?.nativeTransactions?.[0]?.lineItems?.offerDetails?.basePlanId})
      }
      futurePlanTransactionId.value = futurePlan?.transaction_id;
    })
  }
}

const productUpdated = (data: any) => {
  backPlans.value?.forEach((plan: any) => {
    const product = store.get(plan?.subscriptionPlan?.product_id);
    if (plan?.apple_mobile_plan_id === product?.id || plan?.google_mobile_plan_id === product?.id) {
      return;
    }
    plan.id = product?.id;
    plan.title = product?.title;
    plan.price = product?.pricing;
    plan.description = product?.description;
    plan.canPurchase = product?.canPurchase;
    plan.owned = product?.owned;
  });
};

const errorEvent = (error: any) => {
  const toast = useToast();

  // unblock subscribe button
  setPaymentLoader(false);
  setUnlockedButton(true);
  toggleBlock(false);
  
  toast.error(error?.message)
}

export const disableListeners = () => {
  store.off(productApproved);
  store.off(productVerified);
  store.off(productUnverified);
  store.off(errorEvent);
}

export const useInAppPurchase = async () => {
  await initializeApollo().query({
    query: MobilePlans,
  }).then(async (response) => {
    identifier.value = (await Device.getId())?.identifier;

    priceString.value = '0';

    const user: User = StorageService.getUser();

    backPlans.value = response.data.mobilePlans;
    if (isMobile.value && user) {
      
      if(isPlatform('ios')) {
        validationUrl.value = `${process.env.VUE_APP_IAP_RECEIPT_VALIDATION_URL}${user.id}/com.netsells.sdcn/${priceString.value}/${identifier.value}`;
      } else if(isPlatform('android')) {
        validationUrl.value = `${process.env.VUE_APP_IAP_RECEIPT_VALIDATION_URL}${user.id}/${user?.team?.plan?.google_mobile_plan_id}/${priceString.value}/${identifier.value}`;
      }

      store.verbosity = LogLevel.DEBUG;
      store.validator = validationUrl.value;
      store.applicationUsername = encodeIdToUuid(+user?.id);
      CdvPurchase.store.validator_privacy_policy = ['analytics', 'support', 'tracking', 'fraud'];

      const plans: any = backPlans.value
        ?.filter((value: any) => isPlatform('ios') ? value?.apple_mobile_plan_id : value?.google_mobile_plan_id)
        ?.map((p: any) => {
          const plan = {
            type: ProductType.PAID_SUBSCRIPTION,
            platform: getPlatform(Platform),
            id: isPlatform('ios') ? p?.apple_mobile_plan_id : p?.google_mobile_plan_id?.split('@')?.[0],
          }
          return plan;
        })
      
      store.register(isPlatform('ios') ? plans : [plans[0]]);

      store.when().approved(productApproved);
      store.when().verified(product => productVerified(product));
      store.when().unverified(productUnverified);
      store.when().productUpdated(product => productUpdated(product));

      store.when().receiptsVerified(() => {
        const objectWithMaxExpiryDate = store.verifiedPurchases?.reduce((max: any, purchase: any) => {
          return (purchase?.expiryDate > max?.expiryDate) ? purchase : max;
        }, store.verifiedPurchases?.[0]);

        const isValidDate = objectWithMaxExpiryDate?.expiryDate > new Date().valueOf();

        if(!isEmpty(lastProduct.value)) {
          StorageService.setItem('currentPlan', isPlatform('ios')
            ? lastProduct.value?.raw?.collection[0]
            : {...lastProduct.value?.raw?.collection[0], id: lastProduct.value?.nativeTransactions?.[0]?.lineItems?.offerDetails?.basePlanId}
          )
        }
        
        if(!!lastProduct.value?.raw?.team_id && lastProduct.value?.raw?.team_id != user?.team?.id && isValidDate) {
          StorageService.setItem('subscriptionOfAnotherUser', true)
          setPaymentLoader(false);
          setUnlockedButton(true);
          toggleBlock(false);
        }
      })
      store.error(errorEvent);

      store.ready(() => {
        products.value = store.products;
        if(isPlatform('ios')) {
          StorageService.setItem('mobilePlans', products.value);
        } else {
          StorageService.setItem('mobilePlans', products.value?.[0]?.offers);
        }
      });

      (store as any).initialize(
        [
          getPlatform(Platform),
          ProductType.PAID_SUBSCRIPTION,
          {autoFinish: true}
        ]
      );
    }
  })
}

export const orderProduct = async (subscriptionId: string) => {
  const user: User = StorageService.getUser();
  iapProduct.value = (store as any).get(
    subscriptionId,
    getPlatform(Platform),
    ProductType.PAID_SUBSCRIPTION
  );
    
  if(isPlatform('ios')) {
    iapProduct.value.getOffer().order();
  } else {
    offerId.value = subscriptionId;

    const additionalData = {
      googlePlay: {
        replacementMode: GooglePlay.ReplacementMode.DEFERRED,
        accountId: `${user?.id}`,
        profileId: `${identifier.value}|${offerId.value}`,
      }
    }
    const offer = StorageService.getItem('mobilePlans')?.find((p: any) => p?.id === subscriptionId);
    store.order(offer, additionalData)
  }
}