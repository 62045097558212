import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent } from "vue"

import { IonApp, isPlatform } from '@ionic/vue';
import { computed, onMounted, reactive, ref, watch } from "vue";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import router from "@/router";
import { SplashScreen } from "@capacitor/splash-screen";
import { initializeApollo, receivedEvent, receivedMaintenanceEnd, receivedMaintenanceStart } from "@/apollo"
import BaseCard from '@/modules/General/components/base/BaseCard.vue';
import EventsListItem from '@/modules/Notifications/components/EventsListItem.vue';
import { redirectToEntityPage } from '@/helpers/redirectToPageByEntity';
import { RouteNamesEnum } from "@/const/routes";
import LocationAccessModal from '@/modules/General/components/LocationAccessModal.vue';
import { useRoute } from 'vue-router';
import StorageService from './helpers/storageService';
import { isAbleToDo } from './helpers/isAbleToDo';
import { OnFlightTypeUpdates, PaidSubscriptionStatus, Plan, ProfileSettingsEnum } from './gql/graphql';
import { handleFirebaseUserTracking } from './helpers/handleFirebaseUserTracking';
import { useUserStore } from './modules/General/store/userStore';
import { isEmpty, isEqual } from 'lodash';
import { setFirebaseUserOnline } from './helpers/toggleFirebaseUserIsOnline';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Loader } from '@googlemaps/js-api-loader';
import { setGoogleObj, googleObj } from './helpers/setGoogleObj';
import { subscribeWithInterval, unsubscribeFromAllSubscriptions } from './helpers/subscribeToOnFlightUpdates';
import PaymentLoader from '@/modules/Subscription/components/PaymentLoader.vue'
import { paymentLoader } from './helpers/setPaymentLoader';
import { isMobile } from "./helpers/isMobile";
import { Me } from './graphql/queries/me';
import { learnMore } from './const/learnMore';
import { authStorage } from './helpers/authStorage';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const google = ref();
const isGoogleSet = ref(false);
const event = ref();
const maintenance = ref();
const isLocationPermissionCompatibleRoute = ref(false);
const isPaymentLoaderVisible = ref(false)
const areCookiesAccepted = ref(StorageService.getItem('areCookiesAccepted'))

const cookies = reactive({
  type: 'Cookies',
  name: 'Cookies',
  description: '🍪 We use cookies to make your experience better. By continuing to browse, you agree to our use of cookies.'
});

const getIsLocationPermissionCompatibleRoute = computed(() => isLocationPermissionCompatibleRoute.value)
const user = computed(() => useUserStore()?.user);

const route = useRoute();
const userStore = useUserStore();

const onConfirmCookies = () => {
  StorageService.setItem('areCookiesAccepted', 'true');
  areCookiesAccepted.value = StorageService.getItem('areCookiesAccepted')
}

const onLearnMore = () => {
  cookies.name = '';
  cookies.description = learnMore;
};

const updateUserInUserStore = () => {
  const user = StorageService.getUser();
  if(!isEqual(user, userStore?.user)) {
    userStore.updateUserStore(StorageService.getUser())
  }
}
setInterval(() => updateUserInUserStore(), 1000);

onMounted(async () => {
  App.addListener('appStateChange', appStateChangeCallback);
  await SplashScreen.hide();
});

watch(() => route, async () => {
  if(route.meta.requiresAuth) {
    isLocationPermissionCompatibleRoute.value = true
  }
}, {deep: true, immediate: true})


watch(() => receivedEvent, () => {
  if(receivedEvent.value) {
    event.value = receivedEvent.value;
    setTimeout(() => {event.value = null}, 5000)
  }
}, {deep: true, immediate: true});

watch(() => paymentLoader, () => {
  isPaymentLoaderVisible.value = paymentLoader.value;
}, {deep: true, immediate: true});

watch(() => receivedMaintenanceStart, () => {
  if(receivedMaintenanceStart.value) {
    maintenance.value = receivedMaintenanceStart.value;
  }
}, {deep: true, immediate: true});

watch(() => receivedMaintenanceEnd, () => {
  if(receivedMaintenanceEnd.value) {
    maintenance.value = null;
  }
}, {deep: true, immediate: true});

watch(() => googleObj, async () => {
  if (!googleObj.value && !isGoogleSet.value) {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      libraries: ['places', 'geometry'],
      language: 'en',
      region: 'UK'
    });
    await loader.importLibrary('marker');
    await loader.importLibrary('maps');

    setGoogleObj(window.google);
    isGoogleSet.value = true;
    
    google.value = googleObj.value;
    
    new google.value.maps.places.AutocompleteService();
    new google.value.maps.Geocoder();
    new google.value.maps.marker.AdvancedMarkerElement();
  }
}, {deep: true, immediate: true})

const closeMaintenanceMessage = () => maintenance.value = null;

App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
  if(event.url.includes('/impersonate/')) {
    router.push({ name: RouteNamesEnum.Impersonate, params: { token: event.url.split('/impersonate/').pop() }});
  } else if(event.url.includes('/create-password?')) {
    router.push({ name: RouteNamesEnum.CreatePassword, query: { token: event.url.split('/create-password?').pop() }});
  } else if(event.url.includes('/update-password?')) {
    router.push({ name: RouteNamesEnum.UpdatePassword, query: { token: event.url.split('/update-password?').pop() }});
  }
});

const appStateChangeCallback = async ({ isActive }: { isActive: boolean }) => {
  if(isMobile.value) {
    const newVersionAvailable = await isUpdateAvailable();
    if(newVersionAvailable) {
      event.value = {type: OnFlightTypeUpdates.AppUpdate, name: 'SDCN update alert!', date: '', description: 'Keep your mobile app running smoothly with the latest version. For optimal performance, remember to clear your browser cache.'};
    }
  }
  if(!isEmpty(user.value)) {
    unsubscribeFromAllSubscriptions();
    subscribeWithInterval(user.value);
    if (isActive) {

      // remove in some time (hack to skip errors while upgrading token storage from LS to cookies/capacitor storage)
      if(StorageService.getItem('access_token')) {
        await authStorage.setToken(StorageService.getItem('access_token'));
        StorageService.removeItem('access_token');
      }

      await initializeApollo().query({
        query: Me,
      }).then((response) => {
        if ((isAbleToDo(user.value, ProfileSettingsEnum.Tracking) || isAbleToDo(user.value, ProfileSettingsEnum.JobNotificationTracking)) && !StorageService.getItem('isPositionWatching')) {
          handleFirebaseUserTracking(user.value);
        }
        setFirebaseUserOnline(user.value, true)

        if((isPlatform('ios') ? response?.data?.me?.team?.plan?.verified : true) && (!StorageService.getUser()?.team?.is_active_subscriber || (StorageService.getUser()?.team?.is_active_subscriber && response.data?.me?.team?.allPlans?.some((p: Plan) => p?.status === PaidSubscriptionStatus.Future))) && (route.name === RouteNamesEnum.Subscription || route.name === RouteNamesEnum.SubscriptionEdit || route.name === RouteNamesEnum.AddCompanySubscription)) {
          router.push({name: RouteNamesEnum.Notifications});
        }
        StorageService.setUser(response?.data?.me)
      })
    } else {
      setFirebaseUserOnline(user.value, false)
    }
  }
};

const isUpdateAvailable = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  return +result?.updateAvailability === 2
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      (maintenance.value)
        ? (_openBlock(), _createBlock(BaseCard, {
            key: 0,
            class: "event__card-maintenance"
          }, {
            default: _withCtx(() => [
              _createVNode(EventsListItem, {
                event: maintenance.value,
                onOnClose: closeMaintenanceMessage,
                "is-close-button-required": ""
              }, null, 8, ["event"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_unref(isMobile) && !areCookiesAccepted.value)
        ? (_openBlock(), _createBlock(BaseCard, {
            key: 1,
            class: "event__card-cookies"
          }, {
            default: _withCtx(() => [
              _createVNode(EventsListItem, {
                event: cookies,
                onOnClose: onConfirmCookies,
                onOnLearnMore: onLearnMore,
                "is-confirm-button-required": ""
              }, null, 8, ["event"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (event.value)
        ? (_openBlock(), _createBlock(BaseCard, {
            key: 2,
            class: "event__card",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(redirectToEntityPage)(event.value)))
          }, {
            default: _withCtx(() => [
              _createVNode(EventsListItem, { event: event.value }, null, 8, ["event"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      (getIsLocationPermissionCompatibleRoute.value)
        ? (_openBlock(), _createBlock(LocationAccessModal, { key: 3 }))
        : _createCommentVNode("", true),
      (isPaymentLoaderVisible.value)
        ? (_openBlock(), _createBlock(PaymentLoader, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})