<template>
  <div class="header__container">
    <div class="header" :class="{ desktop: isDesktop }">
      <ion-icon
        src="assets/icon/logo.svg"
        class="logo"
        alt="logo"
      />
      <div class="logo-description" :class="{ desktop: isDesktop }">
        <slot name="description">

        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonIcon, } from "@ionic/vue";
import {useBreakpoint} from "@/hooks/useBreakpoint";
const { isDesktop } = useBreakpoint();

</script>

<style scoped lang="scss">
.header__container {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  min-height: var(--header-min-height, calc(200px + var(--ion-safe-area-top)));
  z-index: 10;
  position: relative;
  padding: calc(24px + var(--ion-safe-area-top)) 31px 16px;
  background: no-repeat url(/public/assets/images/header.svg) 50% 100% / 100% 29px , linear-gradient(168.06deg, #176897 -0.85%, #06334E 100%);
}

.logo {
  width: 168px;
  height: 64px;
  display: block;
  min-width: 60px;
  min-height: 60px;
  margin: 0 auto;
}

.logo-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: var(--ion-color-white);

  &:not(.desktop):deep(p) {
    margin: 15px 0 0;
  }
}
@media only screen and (min-width: 1024px) {
  .header {
    background: none;
  }
}

@media only screen and (max-height: 830px){
  .header.desktop {
    min-height: 0;
    margin-bottom: 0;
  }
}
</style>
