import { initializeApollo, receivedEvent, receivedMaintenanceEnd, receivedMaintenanceStart } from "@/apollo";
import { EventRedirectTypesEnum, JobGroupsEnum, OnFlightTypeUpdates, PaidSubscriptionStatus, Plan, ProfileSettingsEnum, User } from "@/gql/graphql";
import { OnFlightChanges } from "@/graphql/subscriptions/onFlightChanges";
import StorageService from "@/helpers/storageService";
import { isAbleToDo } from "./isAbleToDo";
import { deactivateFirebaseUser } from "./updateFirebaseUserLocation";
import { handleFirebaseUserTracking } from "./handleFirebaseUserTracking";
import { RouteNamesEnum } from "@/const/routes";
import router from "@/router";
import { block } from "./setBlockUpdateUserOnSwapSubscription";
import { setHasUnreadEvents } from "./setUnreadEvents";
import { setIncomingMessage } from "./setIncomingMessage";
import { setHasUnseenMessages } from "./setUnseenMessages";
import { isPlatform } from "@ionic/vue";
import dayjs from "dayjs";
import { Logout } from "@/graphql/mutations/logout";
import { clearUpdatePosition } from "./tracking";
import { setFirebaseUserOnline } from "./toggleFirebaseUserIsOnline";
import OneSignalHandler from '@/ts/onesignal';
import { isMobile } from '@/helpers/isMobile';
import { disableListeners } from "./cordova-plugin-purchase";

const OneSignal = new OneSignalHandler();

let subscription: any;
const subscribeToOnFlightTypeUpdates = async (user: User) => {
  if(new Date(user?.future_maintenance).valueOf() > Date.now()) {
    receivedMaintenanceStart.value = {type: 'Maintenance', name: 'Maintenance', date: '', description: `Prepare for Maintenance: Beginning ${(user?.future_maintenance) ? dayjs( user?.future_maintenance).format('DD/MM/YYYY HH:mm') : 'soon'}. Your support means the world!`};
  }
  subscription = await initializeApollo()?.subscribe({
    query: OnFlightChanges,
    variables: { userID: user.id },
  }).subscribe({
    next: async (response) => {
      if (response.data?.onFlightChanges?.type === 'LOGOUT') {
        deactivateFirebaseUser(user);
        unsubscribeFromAllSubscriptions();
        clearUpdatePosition(user);
        setFirebaseUserOnline(user, false);
        if(isMobile.value) {
          OneSignal.setExternalId('');
        }

        initializeApollo()
          .mutate({ mutation: Logout })
          .then(() => {
            if(isMobile.value) {
              disableListeners();
            }
            
            StorageService.clearAll();
            // StorageService.setItem('isAlreadySubscribed', 'false');
            router.push({name: RouteNamesEnum.Logout})
            return;
          });
      }
      if (response.data?.onFlightChanges?.type === OnFlightTypeUpdates.Maintenance) {
        receivedMaintenanceStart.value = {type: 'Maintenance', name: 'Maintenance', date: '', description: `Prepare for Maintenance: Beginning ${response.data?.onFlightChanges?.maintenance_date ? dayjs(response.data?.onFlightChanges?.maintenance_date).format('DD/MM/YYYY HH:mm') : 'soon'}. Your support means the world!`};
      }
      if (response.data?.onFlightChanges?.type === 'MAINTENANCE_FINISHED') {
        receivedMaintenanceEnd.value = {};
      }
      if (response.data?.onFlightChanges?.type === OnFlightTypeUpdates.AppUpdate && !isPlatform('android') && !isPlatform('ios')) {
        receivedEvent.value = {type: response.data?.onFlightChanges?.type, name: 'SDCN update alert!', date: '', description: 'Keep your mobile app running smoothly with the latest version. For optimal performance, remember to clear your browser cache.'};
      }
      if(response.data?.onFlightChanges?.subscription_expired) {
        StorageService.removeItem('currentPlan')
      }
      if (response.data?.onFlightChanges?.type === OnFlightTypeUpdates.Event) {
        receivedEvent.value = response.data?.onFlightChanges?.event;
        setHasUnreadEvents(true)
      }
      if (response.data?.onFlightChanges?.type === OnFlightTypeUpdates.ChatMessage) {
        if(router?.currentRoute.value?.name === RouteNamesEnum.Chat) {
          setIncomingMessage(response.data?.onFlightChanges?.message)
        }
        if (response.data?.onFlightChanges?.message?.participation?.messageable?.id !== user.id && !(user.is_primary && user.team?.users?.find((u: User | null) => u?.id === response.data?.onFlightChanges?.message?.participation?.messageable?.id))) {
          setHasUnseenMessages(true)
          receivedEvent.value = {
            redirect_entity: EventRedirectTypesEnum.Job,
            role: JobGroupsEnum.ChatMessage,
            id: response.data?.onFlightChanges?.message.participation.conversation.job_id,
            name: `New Message for #${response.data?.onFlightChanges?.message.participation.conversation.job_id}`,
            description: response.data?.onFlightChanges?.message.body
          };
        }
      }
      if ((response.data?.onFlightChanges?.type === OnFlightTypeUpdates.User && (!StorageService.getItem('onflight_timestamp') || +StorageService.getItem('onflight_timestamp') < +response.data?.onFlightChanges?.timestamp)) || response.data?.onFlightChanges?.type === OnFlightTypeUpdates.Subscription) {
        StorageService.setItem('onflight_timestamp', response.data?.onFlightChanges?.timestamp)
        if(!block.value) {
          if (
            (isAbleToDo(user, ProfileSettingsEnum.Tracking)) && (!isAbleToDo(response.data?.onFlightChanges?.user, ProfileSettingsEnum.Tracking))
          ) {
            deactivateFirebaseUser(user);
          } else if (
            (!isAbleToDo(user, ProfileSettingsEnum.Tracking) && isAbleToDo(response.data?.onFlightChanges?.user, ProfileSettingsEnum.Tracking))
            || (!isAbleToDo(user, ProfileSettingsEnum.JobNotificationTracking) && isAbleToDo(response.data?.onFlightChanges?.user, ProfileSettingsEnum.JobNotificationTracking))
          ) {
            handleFirebaseUserTracking(response.data?.onFlightChanges?.user);
          }
          if(response.data?.onFlightChanges?.user?.team?.is_active_subscriber && (!StorageService.getUser()?.team?.is_active_subscriber || response.data?.onFlightChanges?.user?.team?.plan?.apple_mobile_plan_id !== StorageService.getUser()?.team?.plan?.apple_mobile_plan_id || response.data?.onFlightChanges?.user?.team?.plan?.google_mobile_plan_id !== StorageService.getUser()?.team?.plan?.google_mobile_plan_id || response.data?.onFlightChanges?.user?.team?.allPlans?.some((p: Plan) => p?.status === PaidSubscriptionStatus.Future))) {
            router.push({name: RouteNamesEnum.Notifications});
          } else if(!response.data?.onFlightChanges?.user?.team?.is_active_subscriber && StorageService.getUser()?.team?.is_active_subscriber) {
            router.push({name: RouteNamesEnum.CompanyEmail});
          }
          StorageService.setUser({...response.data?.onFlightChanges?.user, is_impersonated: StorageService.getUser()?.is_impersonated});
        }
      }
    },
    error: (error) => {
      console.error('Subscription error:', error);
    },
  });
}

const unsubscribeFromAllSubscriptions = () => {
  if (subscription) {
    subscription.unsubscribe();
    StorageService.setItem('isAlreadySubscribed', 'false');
  }
}

const subscribeWithInterval = (user: User) => {
  if (!StorageService.getItem('isAlreadySubscribed')) {
    subscribeToOnFlightTypeUpdates(user)
    StorageService.setItem('isAlreadySubscribed', 'true');

    setInterval(() => {
      if (subscription) {
        unsubscribeFromAllSubscriptions()
        StorageService.setItem('isAlreadySubscribed', 'false');
      }

      subscribeToOnFlightTypeUpdates(user)
      StorageService.setItem('isAlreadySubscribed', 'true');
    }, process.env.VUE_APP_UPDATE_SUBSCRIBE_INTERVAL)
  }
}

export { subscribeToOnFlightTypeUpdates, unsubscribeFromAllSubscriptions, subscribeWithInterval }