<template>
  <div class="location-access-modal" v-if="modalId != 0 && isMobile" :class="{ popup: modalId > 2 }">
    <authentication-header />
    <div class="main ion-padding-horizontal">
      <div class="location-icon">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="32" cy="32" r="32" fill="#0D5F8F" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.3604 30.2219L20.3333 30.2348C19.4517 30.6688 19.7549 32.0115 20.7513 32.0115H32.0013V43.2533C32.0013 44.2599 33.3716 44.559 33.791 43.6439L44.1035 21.1439C44.4657 20.3537 43.6506 19.5387 42.8604 19.9012L20.3604 30.2219Z"
            fill="white" />
        </svg>
      </div>
      <h3>Map-Based Vehicle Coordination & Proximity Job Alerts</h3>
      <div class="text-container" v-if="modalId === 1">
        <ion-text color="dark-grey"><strong>With this feature, your vehicle’s location on the map is accessible to other SDCN users, enabling them to assign jobs directly. Moreover, we’ll keep you posted on job openings in close proximity.</strong></ion-text>
        <ion-text color="dark-grey">You can switch off these features in your user profile anytime.</ion-text>
        <div class="group-button ion-padding-horizontal">
          <ion-button expand="block" fill="outline" @click="onCancel">
            No, thanks
          </ion-button>
          <ion-button expand="block" color="primary" @click="onGiveAccess">
            Try it!
          </ion-button>
        </div>
      </div>
      <div class="text-container" v-if="modalId === 2">
        <div>
          <ion-text color="dark-grey"><strong>Access to your location is essential for Map-Based Vehicle Coordination & Proximity Job Alerts.</strong></ion-text>
          <ion-text color="dark-grey">With these features, other SDCN users will be able to assign jobs to you directly. Moreover, you’ll be posted on job openings in close proximity.</ion-text>
          <ion-text color="dark-grey">Navigate to the location settings on your mobile device and allow apps to request your location.</ion-text>
          <ion-text color="dark-grey">In your device settings, find the SDCN app settings and grant permission for tracking and precise location access.</ion-text>
        </div>
        <div class="group-button ion-padding-horizontal">
          <ion-button expand="block" fill="outline" @click="onCancel">
            Disable all features
          </ion-button>
          <ion-button expand="block" color="primary" @click="onSettings">
            Go to Settings
          </ion-button>
        </div>
      </div>
      <div v-if="modalId === 3">
        <ion-text color="dark-grey"><strong>With this feature, your vehicle’s location on the map is accessible to other SDCN users, enabling them to assign jobs directly. Moreover, we’ll keep you posted on job openings in close proximity.</strong></ion-text>
        <ion-text color="dark-grey">You can switch off these features in your user profile anytime.</ion-text>
        <div class="group-button ion-padding-horizontal">
          <ion-button expand="block" fill="outline" @click="onCancel">
            No, thanks
          </ion-button>
          <ion-button expand="block" color="primary" @click="onGiveAccess">
            Try it!
          </ion-button>
        </div>
      </div>
      <div v-if="modalId === 4">
        <div>
          <ion-text color="dark-grey"><strong>Access to your location is essential for Map-Based Vehicle Coordination & Proximity Job Alerts.</strong></ion-text>
          <ion-text color="dark-grey">With these features, other SDCN users will be able to assign jobs to you directly. Moreover, you’ll be posted on job openings in close proximity.</ion-text>
          <ion-text color="dark-grey">Navigate to the location settings on your mobile device and allow apps to request your location.</ion-text>
          <ion-text color="dark-grey">In your device settings, find the SDCN app settings and grant permission for tracking and precise location access.</ion-text>
        </div>
        <div class="group-button ion-padding-horizontal">
          <ion-button expand="block" fill="outline" @click="onCancel">
            Disable all features
          </ion-button>
          <ion-button expand="block" color="primary" @click="onSettings">
            Go to Settings
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonButton, IonText, isPlatform } from "@ionic/vue";
import { ref, computed, onMounted } from 'vue';
import { ProfileSettingsEnum } from "@/gql/graphql";
import AuthenticationHeader from '@/modules/Auth/components/AuthenticationHeader.vue';
import { useMutation } from "@vue/apollo-composable";
import { EditProfile } from "@/graphql/mutations/editProfile";
import { isAbleToDo } from "@/helpers/isAbleToDo";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { getIosTrackingPermission, getCapacitorGeoLocationPermission, requestLocationPermissions, checkLocationPermissions } from "@/helpers/requestLocationPermissions"
import { UpdateFleet } from "@/graphql/mutations/updateFleet";
import StorageService from "@/helpers/storageService";
import { modalId, setModalId } from '@/helpers/setPermissionsModal';
import { isMobile } from "@/helpers/isMobile";

let user = StorageService.getUser();

const { mutate: editProfile } = useMutation(EditProfile);
const { mutate: updateFleet } = useMutation(UpdateFleet);

const permissionText = ref();

onMounted(async () => {
  const iosTrackingStatus = await getIosTrackingPermission();
  const capacitorGeolocationStatus = await getCapacitorGeoLocationPermission();

  if (iosTrackingStatus !== 'authorized' && capacitorGeolocationStatus !== 'granted') {
    permissionText.value = 'tracking and location permissions';
  } else if (iosTrackingStatus !== 'authorized') {
    permissionText.value = 'tracking permission';
  } else if (capacitorGeolocationStatus !== 'granted') {
    permissionText.value = 'location permission';
  }
});

const onCancel = () => {
  editProfile({
    input: {
      id: +user.id,
      name_first: user.name_first,
      name_last: user.name_last,
      email: user.email,
      phone: user.phone,
      job_notification_tracking: false,
      post_work: isAbleToDo(user, ProfileSettingsEnum.PostWork),
      has_full_access: user.has_full_access,
      tracking: false
    }
  })
    .then(async profileResponse => {
      if (!profileResponse?.errors) {
        setModalId(0);
        StorageService.setItem('user', profileResponse?.data.editProfile);
        user = StorageService.getUser();
        if (profileResponse?.data.editProfile?.vehicle?.length) {
          await updateFleet({
            input: {
              id: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.id,
              name: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.name,
              vehicle_id: profileResponse?.data.editProfile?.vehicle?.[0]?.id,
              temporary_availability: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.temporary_availability,
              default_availability: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.default_availability,
              user_id: profileResponse?.data.editProfile?.id,
              tracking: isAbleToDo(profileResponse?.data.editProfile, ProfileSettingsEnum.Tracking)
            }
          })
        }
      }
    })
};

const onSettings = async () => {
  if (isPlatform('android')) {
    NativeSettings.openAndroid({
      option: AndroidSettings.ApplicationDetails,
    })
  } else if (isPlatform('ios')) {
    NativeSettings.openIOS({
      option: IOSSettings.App,
    })
    const iosTrackingPermission = await getIosTrackingPermission();
    const capacitorGeolocationPermission = await getCapacitorGeoLocationPermission();
    if (iosTrackingPermission !== 'authorized' || capacitorGeolocationPermission !== 'granted') {
      await checkLocationPermissions(isSmallModal.value);
      return;
    }
  }
}

const isSmallModal = computed(() => modalId.value === 3 || modalId.value === 4);

const onGiveAccess = async () => {
  const result = await requestLocationPermissions(isSmallModal.value);
  if (result === 'authorized' || result === 'granted') {
    setModalId(0);
  } else {
    await checkLocationPermissions(isSmallModal.value);
  }
}
</script>

<style lang="scss" scoped>
.location-access-modal {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000000;
  background: var(--ion-color-white);
  --background: var(--ion-color-white);

  .header__container {
    --header-min-height: calc(160px + var(--ion-safe-area-top));
  }

  .main {
    height: calc(100vh - (160px + var(--ion-safe-area-top) + 30px));
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    min-height: 390px;
  }

  .location-icon {
    text-align: center;
    padding: 0 0 25px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: center;
    margin: 0 0 30px;
  }

  
  ion-text {
    max-width: 300px;
    margin: 0 auto 10px;
    display: block;
    font-size: 14px;
    line-height: 21px;
    color: var(--ion-color-dark-grey);
    text-align: center;

    strong {
      font-weight: 500;
    }
  }
  
  .text-container {
    height: calc(100vh - (160px + var(--ion-safe-area-top)));
    overflow-y: scroll; 
  }

  .group-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 80px;

    .button {
      width: calc(50% - 8px);
    }
  }

  &.popup {
    background: none;
    --background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:after {
      position: fixed;
      z-index: 1;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: var(--ion-color-dark);
      --background: var(--ion-color-dark);
      opacity: 0.3;
    }

    .header__container {
      display: none;
    }

    .main {
      position: relative;
      z-index: 2;
      height: auto;
      min-height: 0;
      padding: 30px 15px;
      background: var(--ion-color-white);
      --background: var(--ion-color-white);
    }

    .group-button {
      position: relative;
      padding-bottom: 0;
      padding-top: 20px;
    }
  }
}
</style>