import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main ion-padding-horizontal" }
const _hoisted_2 = {
  key: 0,
  class: "text-container"
}
const _hoisted_3 = { class: "group-button ion-padding-horizontal" }
const _hoisted_4 = {
  key: 1,
  class: "text-container"
}
const _hoisted_5 = { class: "group-button ion-padding-horizontal" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "group-button ion-padding-horizontal" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "group-button ion-padding-horizontal" }

import { IonButton, IonText, isPlatform } from "@ionic/vue";
import { ref, computed, onMounted } from 'vue';
import { ProfileSettingsEnum } from "@/gql/graphql";
import AuthenticationHeader from '@/modules/Auth/components/AuthenticationHeader.vue';
import { useMutation } from "@vue/apollo-composable";
import { EditProfile } from "@/graphql/mutations/editProfile";
import { isAbleToDo } from "@/helpers/isAbleToDo";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { getIosTrackingPermission, getCapacitorGeoLocationPermission, requestLocationPermissions, checkLocationPermissions } from "@/helpers/requestLocationPermissions"
import { UpdateFleet } from "@/graphql/mutations/updateFleet";
import StorageService from "@/helpers/storageService";
import { modalId, setModalId } from '@/helpers/setPermissionsModal';
import { isMobile } from "@/helpers/isMobile";


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationAccessModal',
  setup(__props) {

let user = StorageService.getUser();

const { mutate: editProfile } = useMutation(EditProfile);
const { mutate: updateFleet } = useMutation(UpdateFleet);

const permissionText = ref();

onMounted(async () => {
  const iosTrackingStatus = await getIosTrackingPermission();
  const capacitorGeolocationStatus = await getCapacitorGeoLocationPermission();

  if (iosTrackingStatus !== 'authorized' && capacitorGeolocationStatus !== 'granted') {
    permissionText.value = 'tracking and location permissions';
  } else if (iosTrackingStatus !== 'authorized') {
    permissionText.value = 'tracking permission';
  } else if (capacitorGeolocationStatus !== 'granted') {
    permissionText.value = 'location permission';
  }
});

const onCancel = () => {
  editProfile({
    input: {
      id: +user.id,
      name_first: user.name_first,
      name_last: user.name_last,
      email: user.email,
      phone: user.phone,
      job_notification_tracking: false,
      post_work: isAbleToDo(user, ProfileSettingsEnum.PostWork),
      has_full_access: user.has_full_access,
      tracking: false
    }
  })
    .then(async profileResponse => {
      if (!profileResponse?.errors) {
        setModalId(0);
        StorageService.setItem('user', profileResponse?.data.editProfile);
        user = StorageService.getUser();
        if (profileResponse?.data.editProfile?.vehicle?.length) {
          await updateFleet({
            input: {
              id: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.id,
              name: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.name,
              vehicle_id: profileResponse?.data.editProfile?.vehicle?.[0]?.id,
              temporary_availability: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.temporary_availability,
              default_availability: profileResponse?.data.editProfile?.vehicle?.[0]?.pivot?.default_availability,
              user_id: profileResponse?.data.editProfile?.id,
              tracking: isAbleToDo(profileResponse?.data.editProfile, ProfileSettingsEnum.Tracking)
            }
          })
        }
      }
    })
};

const onSettings = async () => {
  if (isPlatform('android')) {
    NativeSettings.openAndroid({
      option: AndroidSettings.ApplicationDetails,
    })
  } else if (isPlatform('ios')) {
    NativeSettings.openIOS({
      option: IOSSettings.App,
    })
    const iosTrackingPermission = await getIosTrackingPermission();
    const capacitorGeolocationPermission = await getCapacitorGeoLocationPermission();
    if (iosTrackingPermission !== 'authorized' || capacitorGeolocationPermission !== 'granted') {
      await checkLocationPermissions(isSmallModal.value);
      return;
    }
  }
}

const isSmallModal = computed(() => modalId.value === 3 || modalId.value === 4);

const onGiveAccess = async () => {
  const result = await requestLocationPermissions(isSmallModal.value);
  if (result === 'authorized' || result === 'granted') {
    setModalId(0);
  } else {
    await checkLocationPermissions(isSmallModal.value);
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(modalId) != 0 && _unref(isMobile))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["location-access-modal", { popup: _unref(modalId) > 2 }])
      }, [
        _createVNode(AuthenticationHeader),
        _createElementVNode("div", _hoisted_1, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "location-icon" }, [
            _createElementVNode("svg", {
              width: "64",
              height: "64",
              viewBox: "0 0 64 64",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("circle", {
                cx: "32",
                cy: "32",
                r: "32",
                fill: "#0D5F8F"
              }),
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M20.3604 30.2219L20.3333 30.2348C19.4517 30.6688 19.7549 32.0115 20.7513 32.0115H32.0013V43.2533C32.0013 44.2599 33.3716 44.559 33.791 43.6439L44.1035 21.1439C44.4657 20.3537 43.6506 19.5387 42.8604 19.9012L20.3604 30.2219Z",
                fill: "white"
              })
            ])
          ], -1)),
          _cache[21] || (_cache[21] = _createElementVNode("h3", null, "Map-Based Vehicle Coordination & Proximity Job Alerts", -1)),
          (_unref(modalId) === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(IonText), { color: "dark-grey" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("strong", null, "With this feature, your vehicle’s location on the map is accessible to other SDCN users, enabling them to assign jobs directly. Moreover, we’ll keep you posted on job openings in close proximity.", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_unref(IonText), { color: "dark-grey" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("You can switch off these features in your user profile anytime.")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    fill: "outline",
                    onClick: onCancel
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" No, thanks ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    color: "primary",
                    onClick: onGiveAccess
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Try it! ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(modalId) === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("strong", null, "Access to your location is essential for Map-Based Vehicle Coordination & Proximity Job Alerts.", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("With these features, other SDCN users will be able to assign jobs to you directly. Moreover, you’ll be posted on job openings in close proximity.")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Navigate to the location settings on your mobile device and allow apps to request your location.")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("In your device settings, find the SDCN app settings and grant permission for tracking and precise location access.")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    fill: "outline",
                    onClick: onCancel
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Disable all features ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    color: "primary",
                    onClick: onSettings
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Go to Settings ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(modalId) === 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_unref(IonText), { color: "dark-grey" }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("strong", null, "With this feature, your vehicle’s location on the map is accessible to other SDCN users, enabling them to assign jobs directly. Moreover, we’ll keep you posted on job openings in close proximity.", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_unref(IonText), { color: "dark-grey" }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("You can switch off these features in your user profile anytime.")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    fill: "outline",
                    onClick: onCancel
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" No, thanks ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    color: "primary",
                    onClick: onGiveAccess
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" Try it! ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(modalId) === 4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("strong", null, "Access to your location is essential for Map-Based Vehicle Coordination & Proximity Job Alerts.", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("With these features, other SDCN users will be able to assign jobs to you directly. Moreover, you’ll be posted on job openings in close proximity.")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Navigate to the location settings on your mobile device and allow apps to request your location.")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonText), { color: "dark-grey" }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("In your device settings, find the SDCN app settings and grant permission for tracking and precise location access.")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    fill: "outline",
                    onClick: onCancel
                  }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode(" Disable all features ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(IonButton), {
                    expand: "block",
                    color: "primary",
                    onClick: onSettings
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode(" Go to Settings ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})