export const learnMore = `
<b style="font-size: 18px;">Our Use of Cookies</b>

<p>🍪 <b>What are cookies?</b></p>
<p>Cookies are small text files stored on your device when you visit a website. They help improve your browsing experience and enable certain functionalities.</p>

<p><b>The Cookie We Use:</b></p>
<p>Currently, our website uses only one type of cookie:</p>

<p><b>Access Token Cookie:</b></p>
<p>This cookie securely stores an encrypted version of your access token, which is necessary for keeping you logged in and ensuring your session is secure.</p>

<p><b>Why do we use this cookie?</b></p>
<ul>
  <li>To securely store your access token in an encrypted format, protecting it from unauthorized access.</li>
  <li>To maintain your login session and provide a seamless experience while using our website.</li>
</ul>

<p><b>Is this cookie safe?</b></p>
<p>Yes! The access token stored in this cookie is encrypted, meaning it cannot be easily read or misused by anyone.</p>

<p><b>Can you disable this cookie?</b></p>
<p>This cookie is essential for the website to function properly. Disabling it will prevent you from staying logged in and accessing certain features.</p>

<p><b>Your Consent:</b></p>
<p>By continuing to use our website, you consent to the use of this cookie. If you have any questions or concerns, feel free to contact us at  
<a href="mailto:info@samedaycouriernetwork.com">info@samedaycouriernetwork.com</a>.</p>
`