<template>
  <div class="event">
    <ion-icon
      v-if="isCloseButtonRequired"
      color="primary"
      src="assets/icon/close.svg"
      @click="emits(EventNamesEnum.OnClose)"
      class="close-btn"
    />
    <div class="event__first-line" v-if="event?.type !== 'Cookies' || event?.name">
      <div class="event__type">
        <div class="event__icon-container" v-if="event?.type !== 'Cookies'">
          <ion-icon
            slot="icon-only"
            :src="`assets/icon/${itemIcon}-mark.svg`"
            class="event__icon"
          />
        </div>
        <ion-text class="event__name" v-if="event?.name">{{ event?.name }}</ion-text>
      </div>
      <ion-text class="event__date" v-if="event?.pretty_date">{{ date }}</ion-text>
    </div>
    <ion-text v-if="event?.type !== 'Cookies'" class="event__description">{{ event?.description }}</ion-text>
    <div v-else-if="event?.type === 'Cookies'" class="event__description" v-html="event?.description" />
    <div v-if="event?.type === 'Cookies'" class="group-button" :class="{ 'two-buttons': event?.type === 'Cookies'&& event?.name }">
      <ion-button
        v-if="event?.name"
        expand="block"
        color="primary"
        class="button"
        type="button"
        fill="outline"
        @click="emits(EventNamesEnum.OnLearnMore)"
      >
        Learn More
      </ion-button>
      <ion-button
        expand="block"
        color="primary"
        class="button"
        type="button"
        @click="emits(EventNamesEnum.OnClose)"
      >
        Accept
      </ion-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Event, JobGroupsEnum, JobTypesEnum } from '@/gql/graphql';
import { IonText, IonIcon, IonButton } from "@ionic/vue";
import { computed } from 'vue';
import dayjs from "dayjs";
import { EventNamesEnum } from '@/const/events';

const props = defineProps<{
  event?: Event;
  isCloseButtonRequired?: boolean;
  isConfirmButtonRequired?: boolean;
}>();

const emits = defineEmits<{
  (e: EventNamesEnum.OnClose): void;
  (e: EventNamesEnum.OnLearnMore): void;
}>();

const date = computed(() => props.event?.pretty_date?.split(" ")[0].length >= 5 ? dayjs(props.event?.created_at).format('HH:mm') : props.event?.pretty_date)

const itemColor = computed(() => {
  switch (props.event?.group) {
    case JobGroupsEnum.Documents:
      return '#732C2C';
    case JobGroupsEnum.Feedbacks:
      return '#F6AE2D';
    case JobGroupsEnum.JobPosted:
      return '#33658A';
    case JobGroupsEnum.MyWork:
      return '#C5DCA0';
    case JobGroupsEnum.Subscriptions:
      return '#C578A0';
    case JobGroupsEnum.Else:
      return '#333333';
    default:
      return 'black'
  }
});

const itemIcon = computed(() => {
  if(props.event?.name === 'SDCN update alert!') {
    return 'info'
  } else {
    switch (props.event?.type) {
      case JobTypesEnum.Info:
        return 'info';
      case JobTypesEnum.Negative:
        return 'cross';
      case JobTypesEnum.Positive:
        return 'check';
      default:
        return ''
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/theme/modalCloseButton.scss';
.event {
  --background: var(--ion-color-white);
  --border-radius: 10px;
  --inner-border-width: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light-grey);
  padding: 16px;
  width: 100%;
  position: relative;

  &__first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__type {
    display: flex;
    align-items: center;
  }

  &__icon-container {
    height: 24px;
    width: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    position: relative;
    background-color: v-bind(itemColor);
    margin-right: 10px;
  }

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--ion-color-white);
  }

  &__name {
    color: var(--ion-color-dark);
    font-family: var(--ion-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  &__date {
    color: var(--ion-color-grey);
    font-family: var(--ion-font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  &__description {
    color: var(--ion-color-dark);
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    overflow-y: auto;
    max-height: calc(
      100vh - 116px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
  }
}

.group-button {
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ion-color-white);
  padding-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .button {
    width: 100%;
  }
}

.two-buttons .button {
  width: calc(50% - 8px);
}
@media only screen and (min-width: 1024px) {
  .form {
    justify-content: flex-start;
    min-height: 0;
    margin: -20px 16px 0;
    width: auto;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--ion-color-white);
  }
  .group-button {
    justify-content: flex-end;

    .button {
      width: auto;
      min-width: 158px;
      
      &:last-child {
        margin: 0 0 0 10px;
      }
    }
  }
}
</style>