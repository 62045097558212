import { Preferences } from '@capacitor/preferences';
import Cookies from 'js-cookie';
import { isPlatform } from '@ionic/vue';

const ACCESS_TOKEN_KEY = 'access_token';

const userAgent = navigator.userAgent;

const isMac = /Macintosh/.test(userAgent);
const isChrome = !!(window as any).chrome;
const isBrave = !!(window.navigator as any).brave;
const isFirefox = /Firefox/.test(userAgent);
const isOpera = /opera|opr/.test(userAgent);
const isEdge = /Edg/.test(userAgent); // Edge має "Edg" у userAgent
const isSafari = isMac && !isChrome && !isBrave && !isFirefox && !isOpera && !isEdge;

export const authStorage = {
  async setToken(token: string) {
    if (isPlatform('capacitor') || isSafari || isEdge || isFirefox) {
      await Preferences.set({ key: ACCESS_TOKEN_KEY, value: token });
    } else {
      Cookies.set(ACCESS_TOKEN_KEY, token, { secure: true, sameSite: 'Strict' });
    }
  },

  async getToken(): Promise<string | null> {
    if (isPlatform('capacitor') || isSafari || isEdge || isFirefox) {
      const { value } = await Preferences.get({ key: ACCESS_TOKEN_KEY });
      return value;
    } else {
      return Cookies.get(ACCESS_TOKEN_KEY) || null;
    }
  },

  async removeToken() {
    if (isPlatform('capacitor') || isSafari || isEdge || isFirefox) {
      await Preferences.remove({ key: ACCESS_TOKEN_KEY });
    } else {
      Cookies.remove(ACCESS_TOKEN_KEY);
    }
  }
};
