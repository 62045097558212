import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "loader-container" }
const _hoisted_3 = { class: "first-line" }

import BaseSpinner from '@/modules/General/components/base/BaseSpinner.vue';
import { IonText } from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "payment-loader" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(IonText), { color: "primary" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Payment processing")
          ])),
          _: 1
        }),
        _createVNode(BaseSpinner)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_unref(IonText), {
          class: "second-line",
          color: "primary"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("It might take up to 3 minutes")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})