import gql from 'graphql-tag';
import { PlanFragment } from '../fragments/plan';

export const Team = gql`
  query Team($id: ID!) {
    team(id: $id) {
      id
      number_of_users
      can_bid
      company_name
      company_number
      address_line_1
      address_line_2
      town
      county
      postal_code
      payment_method
      billing_frequency
      subscription_amount
      subscription_status
      expire_at
      invoice_recipient_id
      invoice_recipient_name
      invoice_recipient_email
      invoice_recipient_phone
      invoice_logo
      invoice_footer_text
      invoice_including_vat
      vat_number
      created_at
      is_expired
      is_non_stripe_subscription
      is_stripe_canceled_pending
      invoice_address_line_1
      invoice_address_line_2
      invoice_town
      invoice_county
      invoice_postal_code
      use_company_address
      external_invoice_recipient
      is_active_subscriber
      lat
      lng
      logo
      ratings_count
      score
      types {
        id
        name
      }
      invoice_payment_terms
      invoice_account_number
      invoice_account_name
      invoice_sort_code
      invoice_logo_url
      company_registration
      can_query_stripe_invoices
      latest_subscription {
        stripe_status
      }
      plan {
        ...PlanFields
      }
      allPlans {
        ...PlanFields
      }
      blockedTeams {
        id
      }
      documents {
        id
        type {
          id
          amount_required
          expiry_required
          name
        }
        status
        uploads {
          id
          path
          path_url
        }
        amount
        insured_amount
        expiry
      }
      vehicles {
        id
        name
        icon_url
        sort_no
        size
      }
      locations {
        id
        location
        latitude
        longitude
        miles
        created_at
      }
      users {
        id
        name_first
        name_last
        email
        remember_token
        created_at
        updated_at
        phone
        has_demo_account_access
        can_use_client_api
        avatar_url
        inactivated
        is_register_complete
        is_primary
        has_full_access
        profileSettings {
          setting {
            code
          }  
          value
        }
        types {
          id
          name
        }
        score {
          rating
          count
        }
      }
    }
  }
  ${PlanFragment}
`
