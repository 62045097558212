export enum RouteNamesEnum {
  Login = 'LOGIN',
  SignUp = 'SIGN_UP',
  Congratulations = 'CONGRATULATIONS',
  CreatePassword = 'CREATE_PASSWORD',
  UpdatePassword = 'UPDATE_PASSWORD',
  SelectRole = 'SELECT_ROLE',
  ForgotPassword = 'FORGOT_PASSWORD',
  Attention = 'ATTENTION',
  DownloadAppPage = 'DOWNLOAD_APP_PAGE',
  ActionUnauthorized = 'ACTION_UNAUTHORIZED',
  Company = 'COMPANY',
  CompanyEmail = 'COMPANY_EMAIL',
  CompanyContact = 'COMPANY_CONTACT',
  AddCompanyInfo = 'ADD_COMPANY_INFO',
  AddCompanySubscription = 'ADD_COMPANY_SUBSCRIPTION',
  Subscription = 'SUBSCRIPTION',
  SubscriptionEdit = 'SUBSCRIPTION_EDIT',
  SubscriptionEditCards = 'SUBSCRIPTION_EDIT_CARDS',
  SubscriptionInvoices = 'SUBSCRIPTION_INVOICES',
  SubscriptionAddCard = 'SUBSCRIPTION_ADD_CARD',
  CompanyInfo = 'COMPANY_INFO',
  Messages = 'MESSAGES',
  AddressBook = 'ADDRESS_BOOK',
  InvoiceAddress = 'INVOICE_ADDRESS',
  InvoiceRecipient = 'INVOICE_RECIPIENT',
  InvoiceDetails = 'INVOICE_DETAILS',
  InvoiceLogo = 'INVOICE_LOGO',
  MyFleet = 'MY_FLEET',
  Users = 'USERS',
  UsersView = 'USERS_VIEW',
  UsersAdd = 'USERS_ADD',
  UsersEdit = 'USERS_EDIT',
  Documents = 'DOCUMENTS',
  AddVehicle = 'ADD_VEHICLE',
  EditVehicle = 'EDIT_VEHICLE',
  Accounting = 'ACCOUNTING',
  AccountingDriver = 'ACCOUNTING_DRIVER',
  AccountingPoster = 'ACCOUNTING_POSTER',
  AccountingInvoicesDriver = 'ACCOUNTING_INVOICES_DRIVER',
  AccountingInvoicesPoster = 'ACCOUNTING_INVOICES_POSTER',
  ViewInvoice = 'VIEW_INVOICE',
  DocumentList = 'DOCUMENTS_LIST',
  AddViewDocument = 'ADD_VIEW_DOCUMENT',
  ViewDocument = 'VIEW_DOCUMENT',
  Directory = 'DIRECTORY',
  DirectoryTeam = 'DIRECTORY_TEAM',
  EmptyRouterView = 'EMPTY_ROUTER_VIEW',
  DirectoryDetails = 'DIRECTORY_DETAILS',
  Locations = 'LOCATIONS',
  Settings = 'SETTINGS',
  SettingsNotifications = 'SETTINGS_NOTIFICATIONS',
  SettingsProximityJobAlerts = 'SETTINGS_PROXIMITY_JOB_ALERTS',
  SettingsDemo = 'SETTINGS_DEMO',
  SettingsApi = 'SETTINGS_API',
  SettingsPassword = 'SETTINGS_PASSWORD',
	Notifications = 'NOTIFICATIONS',
	LocationEdit = 'LOCATION_EDIT',
	LocationAdd = 'LOCATION_ADD',
  MyWork = 'MY_WORK',
  OpenWork = 'OPEN_WORK',
  Chat = 'CHAT',
  JobsPosted = 'JOBS_POSTED',
  OpenJob = 'OPEN_JOB',
  BrowseJobs = 'BROWSE_JOBS',
  OpenBrowseJob = 'OPEN_BROWSE_JOB',
  UserFeedbacks = 'USER_FEEDBACKS',
  CreateSavedLoadCreate = 'CREATE_SAVED_LOAD_CREATE',
  CreateSavedLoadConfirm = 'CREATE_SAVED_LOAD_CONFIRM',
  CreateSavedLoadVehicle = 'CREATE_SAVED_LOAD_VEHICLE',
  CreateSavedLoadAdditionalInfo = 'CREATE_SAVED_LOAD_ADDITIONAL_INFO',
  EditSavedLoadCreate = 'EDIT_SAVED_LOAD_CREATE',
  EditSavedLoadConfirm = 'EDIT_SAVED_LOAD_CONFIRM',
  EditSavedLoadVehicle = 'EDIT_SAVED_LOAD_VEHICLE',
  EditSavedLoadAdditionalInfo = 'EDIT_SAVED_LOAD_ADDITIONAL_INFO',
  PostJobChoose = 'POST_JOB_CHOOSE',
  SavedLoads = 'SAVED_LOADS',
  PostJobCreate = 'POST_JOB_CREATE',
  PostJobConfirm = 'POST_JOB_CONFIRM',
  PostJobVehicle = 'POST_JOB_VEHICLE',
  PostJobAdditionalInfo = 'POST_JOB_ADDITIONAL_INFO',
  EditJobCreate = 'EDIT_JOB_CREATE',
  EditJobConfirm = 'EDIT_JOB_CONFIRM',
  EditJobVehicle = 'EDIT_JOB_VEHICLE',
  EditJobAdditionalInfo = 'EDIT_JOB_ADDITIONAL_INFO',
  EditStopsCreate = 'EDIT_STOPS_CREATE',
  EditStopsConfirm = 'EDIT_STOPS_CONFIRM',
  Benefits = 'BENEFITS',
  Benefit = 'BENEFIT',
  Help = 'HELP',
  LeaveFeedback = 'LEAVE_FEEDBACK',
  PostFeedback = 'POST_FEEDBACK',
  LiveAvailability = 'LIVE_AVAILABILITY',
  UploadPod = 'UPLOAD_POD',
  UploadElectronicPod = 'UPLOAD_ELECTRONIC_POD',
  UploadStopPod = 'UPLOAD_STOP_PAGE',
  UploadPor = 'UPLOAD_POR',
  ViewPod = 'VIEW_POD',
  ViewElectronicPod = 'VIEW_ELECTRONIC_POD',
  ViewElectronicStopPod = 'VIEW_ELECTRONIC_STOP_POD',
  ViewStopPod = 'VIEW_STOP_POD',
  ViewPor = 'VIEW_POR',
  RaiseInvoice = 'RAISE_INVOICE',
  PreviewInvoice = 'PREVIEW_INVOICE',
  Bids = 'BIDS',
  TeamBids = 'TEAM_BIDS',
  SubmitBid = 'SUBMIT_BID',
  ViewGoodsOnBoard = 'VIEW_GOODS_ON_BOARD',
  ViewStopGoodsOnBoard = 'VIEW_STOP_GOODS_ON_BOARD',
  OnRouteToCollect = 'ON_ROUTE_TO_COLLECT',
  ViewOnRouteToCollect = 'VIEW_ON_ROUTE_TO_COLLECT',
  ArrivedAtCollection = 'ARRIVED_AT_COLLECTION',
  ViewArrivedAtCollection = 'VIEW_ARRIVED_AT_COLLECTION',
  OnRouteToReturn = 'ON_ROUTE_TO_RETURN',
  ViewOnRouteToReturn = 'VIEW_ON_ROUTE_TO_RETURN',
  OnRouteToDeliver = 'ON_ROUTE_TO_DELIVER',
  ViewOnRouteToDeliver = 'VIEW_ON_ROUTE_TO_DELIVER',
  ArrivedAtDestination = 'ARRIVED_AT_DESTINATION',
  ViewArrivedAtDestination = 'VIEW_ARRIVED_AT_DESTINATION',
  AddGoodsOnBoard = 'ADD_GOODS_ON_BOARD',
  AddStopGoodsOnBoard = 'ADD_STOP_GOODS_ON_BOARD',
  ListInvoices = 'LIST_INVOICES',
  JobFeedbacks = 'JOB_FEEDBACKS',
  Logout = 'LOGOUT',
  Impersonate = 'IMPERSONATE',
  OldAndroidApp = 'OLD_ANDROID_APP',
  NotFound = 'NOT_FOUND',
  Maintenance = 'MAINTENANCE',
  UserBidsHistory = 'USER_BIDS_HISTORY',
  AddContact = 'ADD_CONTACT',
  EditContact = 'EDIT_CONTACT',
  ViewContact = 'VIEW_CONTACT',
}
