import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonItem } from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
    disabled: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), {
    class: _normalizeClass(["base-card", { 'base-card--disabled': _ctx.disabled }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})