import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "event" }
const _hoisted_2 = {
  key: 1,
  class: "event__first-line"
}
const _hoisted_3 = { class: "event__type" }
const _hoisted_4 = {
  key: 0,
  class: "event__icon-container"
}
const _hoisted_5 = ["innerHTML"]

import { Event, JobGroupsEnum, JobTypesEnum } from '@/gql/graphql';
import { IonText, IonIcon, IonButton } from "@ionic/vue";
import { computed } from 'vue';
import dayjs from "dayjs";
import { EventNamesEnum } from '@/const/events';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsListItem',
  props: {
    event: {},
    isCloseButtonRequired: { type: Boolean },
    isConfirmButtonRequired: { type: Boolean }
  },
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "8af08df8": (itemColor.value)
}))

const props = __props;

const emits = __emit;

const date = computed(() => props.event?.pretty_date?.split(" ")[0].length >= 5 ? dayjs(props.event?.created_at).format('HH:mm') : props.event?.pretty_date)

const itemColor = computed(() => {
  switch (props.event?.group) {
    case JobGroupsEnum.Documents:
      return '#732C2C';
    case JobGroupsEnum.Feedbacks:
      return '#F6AE2D';
    case JobGroupsEnum.JobPosted:
      return '#33658A';
    case JobGroupsEnum.MyWork:
      return '#C5DCA0';
    case JobGroupsEnum.Subscriptions:
      return '#C578A0';
    case JobGroupsEnum.Else:
      return '#333333';
    default:
      return 'black'
  }
});

const itemIcon = computed(() => {
  if(props.event?.name === 'SDCN update alert!') {
    return 'info'
  } else {
    switch (props.event?.type) {
      case JobTypesEnum.Info:
        return 'info';
      case JobTypesEnum.Negative:
        return 'cross';
      case JobTypesEnum.Positive:
        return 'check';
      default:
        return ''
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isCloseButtonRequired)
      ? (_openBlock(), _createBlock(_unref(IonIcon), {
          key: 0,
          color: "primary",
          src: "assets/icon/close.svg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits(_unref(EventNamesEnum).OnClose))),
          class: "close-btn"
        }))
      : _createCommentVNode("", true),
    (_ctx.event?.type !== 'Cookies' || _ctx.event?.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.event?.type !== 'Cookies')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_unref(IonIcon), {
                    slot: "icon-only",
                    src: `assets/icon/${itemIcon.value}-mark.svg`,
                    class: "event__icon"
                  }, null, 8, ["src"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.event?.name)
              ? (_openBlock(), _createBlock(_unref(IonText), {
                  key: 1,
                  class: "event__name"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.event?.name), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.event?.pretty_date)
            ? (_openBlock(), _createBlock(_unref(IonText), {
                key: 0,
                class: "event__date"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(date.value), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.event?.type !== 'Cookies')
      ? (_openBlock(), _createBlock(_unref(IonText), {
          key: 2,
          class: "event__description"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.event?.description), 1)
          ]),
          _: 1
        }))
      : (_ctx.event?.type === 'Cookies')
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "event__description",
            innerHTML: _ctx.event?.description
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
    (_ctx.event?.type === 'Cookies')
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["group-button", { 'two-buttons': _ctx.event?.type === 'Cookies'&& _ctx.event?.name }])
        }, [
          (_ctx.event?.name)
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                expand: "block",
                color: "primary",
                class: "button",
                type: "button",
                fill: "outline",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (emits(_unref(EventNamesEnum).OnLearnMore)))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Learn More ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(IonButton), {
            expand: "block",
            color: "primary",
            class: "button",
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emits(_unref(EventNamesEnum).OnClose)))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Accept ")
            ])),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})