import OneSignal from 'onesignal-cordova-plugin';
import { redirectToEntityPage } from '@/helpers/redirectToPageByEntity';

export default class OneSignalHandler {
  public async init(): Promise<void> {
    try {
      await OneSignal.initialize(process.env.VUE_APP_ONE_SIGNAL_APP_ID);
      console.log("OneSignal initialized");
      OneSignal.Debug.setLogLevel(6);

      OneSignal.Notifications.addEventListener("click", (event) => {
        if (event.notification.additionalData) {
          redirectToEntityPage(event.notification.additionalData as any);
        }
      });

      const permission = await OneSignal.Notifications.requestPermission();
      console.log("User accepted notifications: " + permission);
    } catch (error) {
      console.error("OneSignal init error:", error);
    }
  }

  public setExternalId(externalId: string): void {
    OneSignal.login(externalId);
  }
}