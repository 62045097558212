import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__container" }

import { IonIcon, } from "@ionic/vue";
import {useBreakpoint} from "@/hooks/useBreakpoint";

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthenticationHeader',
  setup(__props) {

const { isDesktop } = useBreakpoint();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header", { desktop: _unref(isDesktop) }])
    }, [
      _createVNode(_unref(IonIcon), {
        src: "assets/icon/logo.svg",
        class: "logo",
        alt: "logo"
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["logo-description", { desktop: _unref(isDesktop) }])
      }, [
        _renderSlot(_ctx.$slots, "description")
      ], 2)
    ], 2)
  ]))
}
}

})