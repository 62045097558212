import { RouteNamesEnum } from "./routes";

// Accounting & Invoices
const accountingInvoicesRoutes = [
  RouteNamesEnum.Accounting,
  RouteNamesEnum.AccountingDriver,
  RouteNamesEnum.AccountingPoster,
  RouteNamesEnum.AccountingInvoicesDriver,
  RouteNamesEnum.AccountingInvoicesPoster,
  RouteNamesEnum.ViewInvoice,
];

// Directory
const directoryRoutes = [RouteNamesEnum.DirectoryDetails, RouteNamesEnum.Directory];
const directoryTeamRoutes = [RouteNamesEnum.DirectoryTeam];

// Saved Loads & Jobs
const savedLoadsJobCreateRoutes = [
  RouteNamesEnum.CreateSavedLoadCreate,
  RouteNamesEnum.EditSavedLoadCreate,
  RouteNamesEnum.PostJobCreate,
  RouteNamesEnum.EditJobCreate,
];

const savedLoadsJobVehiclesRoutes = [
  RouteNamesEnum.CreateSavedLoadVehicle,
  RouteNamesEnum.EditSavedLoadVehicle,
  RouteNamesEnum.PostJobVehicle,
  RouteNamesEnum.EditJobVehicle,
];

const savedLoadsJobRoutes = [RouteNamesEnum.OpenJob, RouteNamesEnum.SavedLoads];

const savedLoadsJobAndVehiclesRoutes = [...savedLoadsJobRoutes, ...savedLoadsJobVehiclesRoutes];
const savedLoadsJobCreateAndVehicleRoutes = [...savedLoadsJobCreateRoutes, ...savedLoadsJobVehiclesRoutes];

const savedLoadsJobConfirmRoutes = [
  RouteNamesEnum.CreateSavedLoadConfirm,
  RouteNamesEnum.EditSavedLoadConfirm,
  RouteNamesEnum.PostJobConfirm,
  RouteNamesEnum.EditJobConfirm,
];

const savedLoadsJobConfirmAdditionalInfoRoutes = [
  RouteNamesEnum.CreateSavedLoadAdditionalInfo,
  RouteNamesEnum.EditSavedLoadAdditionalInfo,
  RouteNamesEnum.PostJobAdditionalInfo,
  RouteNamesEnum.EditJobAdditionalInfo,
  ...savedLoadsJobConfirmRoutes,
];

const savedLoadsJobStopsConfirmRoutes = [...savedLoadsJobConfirmRoutes, RouteNamesEnum.EditStopsConfirm];

// Other Routes
const postJobRoutes = [
  RouteNamesEnum.PostJobCreate,
  RouteNamesEnum.PostJobConfirm,
  RouteNamesEnum.PostJobVehicle,
  RouteNamesEnum.PostJobAdditionalInfo,
];

const documentsRoutes = [RouteNamesEnum.Documents, RouteNamesEnum.DocumentList, RouteNamesEnum.AddViewDocument];
const chatMessagesRoutes = [RouteNamesEnum.Messages, RouteNamesEnum.Chat];
const logoutRoutes = [RouteNamesEnum.Logout];

const previewInvoiceRoutes = [RouteNamesEnum.PreviewInvoice];
const liveAvailabilityRoutes = [RouteNamesEnum.LiveAvailability];

const workRoutes = [RouteNamesEnum.OpenWork];
const jobRoutes = [RouteNamesEnum.OpenJob];

const jobPostedOpenJobRoutes = [RouteNamesEnum.JobsPosted, RouteNamesEnum.OpenJob];
const myWorkOpenWorkRoutes = [RouteNamesEnum.MyWork, RouteNamesEnum.OpenWork];

export {
  accountingInvoicesRoutes,
  directoryRoutes,
  directoryTeamRoutes,
  savedLoadsJobCreateRoutes,
  savedLoadsJobVehiclesRoutes,
  savedLoadsJobRoutes,
  savedLoadsJobAndVehiclesRoutes,
  savedLoadsJobCreateAndVehicleRoutes,
  savedLoadsJobConfirmRoutes,
  savedLoadsJobConfirmAdditionalInfoRoutes,
  savedLoadsJobStopsConfirmRoutes,
  postJobRoutes,
  documentsRoutes,
  chatMessagesRoutes,
  logoutRoutes,
  previewInvoiceRoutes,
  liveAvailabilityRoutes,
  workRoutes,
  jobRoutes,
  jobPostedOpenJobRoutes,
  myWorkOpenWorkRoutes,
};
