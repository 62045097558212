import gql from 'graphql-tag';
import { PlanFragment } from './plan';

export const UserFragment = gql`
  fragment UserFields on User {
    registration_progress
    id
    is_primary
    name_first  
    name_last
    phone
    email
    avatar_url
    has_full_access
    has_unread_events
    has_unseen_messages
    guide_url
    cache_clear_guide_url
    has_demo_account_access
    can_use_client_api
    is_impersonated
    inactivated
    subscribe_email
    future_maintenance
    team {
      company_number
      address_line_1
      address_line_2
      town
      county
      postal_code
      lng
      lat
      external_invoice_recipient
      company_registration
      expire_at
      id
      can_bid
      invoice_payment_terms
      invoice_account_number
      invoice_account_name
      invoice_sort_code
      invoice_including_vat
      vat_number
      is_active_subscriber
      is_non_stripe_subscription
      is_stripe_canceled_pending
      subscription_status
      score
      payment_method
      can_query_stripe_invoices
      latest_subscription {
				stripe_status
			}
      plan {
        ...PlanFields
      }
      allPlans {
        ...PlanFields
      }
      company_name
      logo
      types {
        name  
      }  
      users {
        id
        name_first
        name_last
        is_primary
      }
    }
    vehicle {
      id
      temporary_availability
      default_availability
      name
      tracking
      until
      vehicle {
        size
        id
      }
    }
    types {
      name  
    }  
    settings
    profileSettings {
      setting {
        type
        code
        title
        options {
          code
          value
        }
        value
      }
      value
    }
    score {
      rating
      count
    }
  }
  ${PlanFragment}
`;
